import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import useTopics, { Topic } from "../hooks/use-topics";
import { YouTubeThumbnail } from "./YouTubeThumbnail";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";
import { Badge } from "@/components/ui/badge";
import { DifficultyClassNameMapping } from "@/pages/Topics/constants";
import { sendFeaturedTopicItemClickEvent } from "@/pages/Topics/utils/trackingEvent";

const Features = () => {
  const navigate = useNavigate();
  const { fetchTopics } = useTopics();
  const [topics, setTopics] = useState<Topic[]>([]);

  useEffect(() => {
    fetchTopics({ filter: { featured: true } }).then(({ topics }) => {
      setTopics(topics);
    });
  }, [fetchTopics]);

  return (
    <Carousel
      opts={{
        align: "start",
      }}
      className="w-full"
    >
      <CarouselContent>
        {topics.length
          ? topics.map((topic, index) => (
              <CarouselItem key={index} className="basis-[60%] md:basis-[35%]">
                <div
                  className="relative max-h-[70vh] hover:cursor-pointer"
                  onClick={() => {
                    sendFeaturedTopicItemClickEvent();
                    navigate(`/topics/${topic.topic_id}`);
                  }}
                >
                  <YouTubeThumbnail url={topic.video_url} quality="high" />
                  <div className="absolute top-1 left-1/2 -translate-x-1/2 min-w-[200px] md:min-w-[300px] text-center">
                    <div
                      className="text-xl font-bold"
                      style={{ textShadow: "1px 1px 2px black" }}
                    >
                      {topic.name.replace(/^[^-]*-/, "")}
                    </div>
                  </div>
                  <div className="absolute bottom-2 left-2">
                    <Badge className="mr-2">{topic.style}</Badge>
                  </div>
                  <div className="absolute bottom-2 right-2">
                    <Badge
                      className={`${DifficultyClassNameMapping[topic.difficulty]}`}
                    >
                      {topic.difficulty}
                    </Badge>
                  </div>
                </div>
              </CarouselItem>
            ))
          : Array.from({ length: 3 }).map((_, index) => (
              <CarouselItem key={index} className="basis-[60%] md:basis-[35%]">
                <div className="max-h-[70vh]">
                  <Skeleton className="aspect-[9/16]" />
                </div>
              </CarouselItem>
            ))}
      </CarouselContent>
    </Carousel>
  );
};

export default Features;
