import { Badge } from "@/components/ui/badge";
import Features from "./components/Features";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { YouTubeThumbnail } from "./components/YouTubeThumbnail";
import useTopics, { Payload, Topic } from "./hooks/use-topics";
import { Skeleton } from "@/components/ui/skeleton";
import { DifficultyClassNameMapping } from "./constants";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { SortAsc } from "lucide-react";
import { DropdownMenuLabel } from "@radix-ui/react-dropdown-menu";
import {
  onFilterStyleButtonClick,
  onSortMenuClose,
  onSortMenuOpen,
  onSortOptionClick,
  sendTopicItemClickEvent,
} from "./utils/trackingEvent";

type SORTING_TYPES = "created" | "challenged_counts";

const STYLES = ["all", "locking", "breaking", "hiphop"];

const Topics = () => {
  const navigate = useNavigate();
  const [topics, setTopics] = useState<Topic[]>([]);
  const [currentStyle, setStyle] = useState<string>("all");
  const [currentTypeOfSorting, setCurrentTypeOfSorting] =
    useState<SORTING_TYPES>("created");
  const { fetchTopics } = useTopics();

  useEffect(() => {
    const payload = {} as Payload;
    fetchTopics(payload).then(({ topics }) => {
      setTopics(topics);
    });
  }, [fetchTopics]);

  const onBadgeClick = (style: string) => {
    setStyle(style);
    onFilterStyleButtonClick(style);
  };

  const filteredTopics = topics
    .filter((topic) => {
      if (currentStyle === "all") return true;
      return topic.style === currentStyle;
    })
    .sort((a, b) => {
      if (currentTypeOfSorting === "challenged_counts") {
        return b.challenged_counts - a.challenged_counts;
      }

      if (currentTypeOfSorting === "created") {
        return new Date(b.created).getTime() - new Date(a.created).getTime();
      }

      return 0;
    });

  return (
    <>
      <h2 className="mb-3 text-2xl font-extrabold">Featured Topics</h2>
      <div className="mt-2">
        <Features />
      </div>

      <h2 className="mt-6 mb-2 text-2xl font-extrabold">Topics</h2>

      {/* filter */}
      <div className="flex items-start">
        <div className="space-x-2">
          {STYLES.map((style, index) => (
            <Badge key={style} onClick={() => onBadgeClick(style)}>
              {style.toUpperCase()}
            </Badge>
          ))}
        </div>
        <div className="ml-auto">
          <DropdownMenu
            onOpenChange={(value) => {
              value ? onSortMenuOpen() : onSortMenuClose();
            }}
          >
            <DropdownMenuTrigger asChild>
              <Button size="icon" className="h-8 w-8">
                <SortAsc className="h-3.5 w-3.5" />
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent className="w-48">
              <DropdownMenuLabel className="text-sm">
                Sorting by
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuRadioGroup
                value={currentTypeOfSorting}
                onValueChange={(value) => {
                  setCurrentTypeOfSorting(value as SORTING_TYPES);
                  onSortOptionClick(value as string);
                }}
              >
                <DropdownMenuRadioItem value="created">
                  Created Date
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="challenged_counts">
                  Challenged Counts
                </DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <div className="md:flex flex-wrap">
        {filteredTopics.length
          ? filteredTopics?.map((topic, index) => (
              <div className="my-4 flex md:w-[50%]" key={topic.topic_id}>
                <div
                  className="w-[30%] md:w-[50%] min-w-[30%] md:min-w-[50%] hover:cursor-pointer"
                  onClick={() => {
                    sendTopicItemClickEvent();
                    navigate(`/topics/${topic.topic_id}`);
                  }}
                >
                  <YouTubeThumbnail url={topic.video_url} />
                </div>
                <div className="ml-4">
                  <h3
                    className="mb-2 text-md font-extrabold hover:cursor-pointer"
                    onClick={() => {
                      sendTopicItemClickEvent();
                      navigate(`/topics/${topic.topic_id}`);
                    }}
                  >
                    {topic.name.replace(/^[^-]*-/, "")}
                  </h3>
                  <div className="space-x-2">
                    <Badge>{topic.style}</Badge>
                    <Badge
                      className={`${DifficultyClassNameMapping[topic.difficulty]}`}
                    >
                      {topic.difficulty}
                    </Badge>
                  </div>
                  <div className="mt-4 text-sm font-extrabold">
                    Challenged Counts: {topic.challenged_counts}
                  </div>
                </div>
              </div>
            ))
          : Array.from({ length: 5 }).map((topic, index) => (
              <div className="my-4 flex" key={index}>
                <div className="w-1/2">
                  <Skeleton className="aspect-[9/16]" />
                </div>
                <div className="ml-2 w-1/2">
                  <Skeleton className="h-4" />
                  <Skeleton className="h-4 mt-1" />
                  <Skeleton className="h-4 mt-1" />
                  <Skeleton className="h-4 mt-1" />
                </div>
              </div>
            ))}
      </div>
    </>
  );
};

export default Topics;
