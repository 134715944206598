const YoutubePlayer = ({ videoId }: { videoId: string }) => {
  return (
    <iframe
      className="w-full h-full max-w-lg max-h-md border-0"
      src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&playlist=${videoId}&loop=1`}
      title="dance-challenge-video"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
    ></iframe>
  );
};

export default YoutubePlayer;
